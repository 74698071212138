import * as React from "react";
import { FC } from "react";
import A4 from "@layouts/A4";
import External from "@components/External";
import Markdown from "@components/Markdown";
import { dateToString } from "@utils/date";

const Index: FC = () => {
  return (
    <A4 margin="letter">
      <div className="flex flex-col gap-12">
        <header className="flex flex-col gap-8">
          <div className="flex justify-end">
            <External />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-end">
              <div className="flex flex-col items-end">
                <h3>Julien Taes</h3>
                <h4>
                  <Markdown text="Brussels\,\ :Belgium:" />
                </h4>
                <h4>{dateToString(new Date(2023, 0, 26), "long")}</h4>
                <a href={`mailto:julien.taes@etoo.me`} aria-label="email">
                  <h4>julien.taes@etoo.me</h4>
                </a>
              </div>
            </div>
            {/* <div>
              <div>
                <h3>Recipent</h3>
              </div>
            </div> */}
          </div>
        </header>

        <main className="flex flex-col gap-8 text-justify">
          <section>
            <p>Dear Sir or Madam,</p>
          </section>

          <section className="flex flex-col gap-4">
            <p>
              <Markdown
                text="
                I am looking for an __internship in cybersecurity__, and I am determined to find the one that best suits me.
                To see if my profile matches your needs, I need to introduce myself as a **creative**, **curious** and **perfectionist** __22 years old cybersecurity student__.
                "
              />
            </p>

            <p>
              <Markdown
                text="
                My **creativity** is my rare value on the market.
                When doing a project in group, I always use my expertise for two main aspects.
                The architecture and the front-end.
                These two are not usually put together but for me they represent the same concept which is design.
                I always make my work well engineer and attractive at the same time.
                "
              />
            </p>

            <p>
              <Markdown
                text="
                My **curiosity** also led me to combine things who aren't usually put together.
                I like to use technologies outside their intended field.
                And make it accessible to the public.
                It is my definition of the hacking mindset.
                For instance, during all my years at the university, I have always used my mandatory projects as a pretext to try new edge technologies. 
                And for my internship, I will apply the same idea.
                I want to discover new technologies, but also get outside of my comfort zone.
                That is why I am looking for an __internship in a big company outside Belgium following a hybrid work model__.
                "
              />
            </p>

            <p>
              <p>
                <Markdown
                  text="
                  My **perfectionism** has good and bad consequences.
                  "
                />
              </p>
              <p>
                <Markdown
                  text="
                  On the good part, I am not a fast learner but a deep learner.
                  Multiple exposition on multiple angles is what works best for me.
                  I also really like automation and non-destructive workflow.
                  I will do everything to not have to do things twice.
                  "
                />
              </p>
              <p>
                <Markdown
                  text="
                  Which introduce perfectly the bad consequences.
                  I tend to be an all or nothing.
                  I cannot invest myself in a project if it is not entirely.
                  But for many years now, I mitigate this issue by using my lucidity.
                  "
                />
              </p>
              <p>
                <Markdown
                  text="
                  I know that making things perfect is impossible.
                  My trick is focus on the mistakes and learn from them to do better the next time.
                  In other words, I redirect my perfectionism onto learning and discovery.
                  That is how I got lucid about my rare values on the market.
                  I'm too young to focus only on my pre-existing passions.
                  I want to try things to see if I like them or not.
                  Furthermore, I do not know all the existing jobs in cybersecurity available to me.
                  "
                />
              </p>
            </p>

            <p>
              Thank you for your time and consideration. And note that you can
              always reach me at{" "}
              <a
                className="inline-flex"
                href={`mailto:julien.taes@etoo.me`}
                aria-label="email"
              >
                <p>julien.taes@etoo.me</p>
              </a>{" "}
              for futher information.
            </p>
          </section>

          <section>
            <p>Sincerely,</p>
            <p>Taes Julien</p>
          </section>
        </main>
      </div>
    </A4>
  );
};

export default Index;
